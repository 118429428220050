import React from 'react'
import { ProjectCard } from './card/ProjectCard'

export const Projects = () => {

  return (
    <div className='min-h-screen flex flex-col text-white py-10 px-6' id='projects'>

      <div class="relative flex items-center">
          <span class="flex-shrink  text-2xl lg:text-4xl text-left text-primary-main uppercase pr-2">Web work</span>
        <div class="flex-grow border-t border-primary-main opacity-40"></div>
      </div>
            
      <div className='pb-10'>Curious about what i've done?</div>

      <div className='space-y-6'>

        <ProjectCard 
          title='E-commerce classic'
          desc='This project was created in 2021 and some dependencies and code issues were fixed now in 2023. 
          It is a simple e-commerce with Transbank as a payment method'
          tech={['React', 'Tailwind', 'Express Js' , 'Firebase', 'vercel', 'Transbank']}
          imgs={['assets/projects/ecom-classic/ecom-classic-home.png', 'assets/projects/ecom-classic/ecom-classic-products.png']}
          url='https://e-commerce-classic-frontend.vercel.app/'
          repo='https://github.com/eandresvera/e-commerce-classic-frontend'
        />

        <hr className='border-primary-main'/>

        <ProjectCard 
          rightSide
          shopifyTheme
          title='Shopify liquid theme'
          desc={`Beautiful, minimalistic & elegant Shopify theme. This project was created in early 2023.`}
          tech={['Liquid', 'Javascript', 'Css', 'Shopify']}
          imgs={['assets/projects/ecom-shopify/mulipurpose1.png', 'assets/projects/ecom-shopify/mulipurpose2.png']}
          url='https://multipurpose-vualapp-theme.myshopify.com/'
          norepo
        />
        
        <hr className='border-primary-main'/>
        
        <ProjectCard 
          shopifyTheme
          title='Shopify liquid theme'
          desc={`Shopify theme designed for tech products, featuring sleek design and seamless user experience. This project was created in early 2023.`}
          tech={['Liquid', 'Javascript', 'Css', 'Shopify']}
          imgs={['assets/projects/ecom-shopify/seller-theme2.png', 'assets/projects/ecom-shopify/seller-theme1.png']}
          url='https://seller-vualapp-theme.myshopify.com/password'
          norepo
        />

        <hr className='border-primary-main'/>

        <ProjectCard 
          rightSide
          title='Demo Admin e-commerce'
          desc='This CRUD was created at the end of 2021. Any write data has been disabled due to database security.'
          tech={['React', 'Tailwind', 'Firebase']}
          imgs={['assets/projects/admins/demo-admin-react.png', 'assets/projects/admins/demo-admin-react2.png']}
          url='https://fir-admin-react.web.app/'
        />

        <hr className='border-primary-main'/>

        <ProjectCard 
          title='Simple headless nextjs wordpress'
          desc='Simple web page created with NextJs and Wordpress as a CMS. 
          NextJs consume Wordpress api and get all data that is displayed on the frontend. This project was deployed on shared hosting.'
          tech={['Next Js', 'GraphQL', 'Tailwind', 'Wordpress']}
          imgs={['assets/projects/kiltro/kiltro_screenshot1.png', 'assets/projects/kiltro/kiltro_screenshot2.png']}
          url='https://www.kiltrobcn.com/'
          repo='https://github.com/eandresvera/kiltro-nextjs'
        />
      </div>

    </div>
  )
}
