import { About } from './components/About';
import { Contact } from './components/Contact';
import { Home } from './components/Home';
import { Projects } from './components/Projects';
import { TechStack } from './components/TechStack';

function App() {
  
  return (
    <div className='min-h-screen bg-darkMode-dark overflow-hidden'>
      <Home />
      <About />
      <TechStack />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;