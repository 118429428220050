import React from 'react'

export const LogoCard = ({ img, name }) => {
  return (
    <div class="max-w-sm p-4 flex justify-center">

      <div class=" bg-darkMode-dark1 w-36 h-36 p-5 hover:bg-darkMode-dark2">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className='flex justify-center'>
          <img class=" h-12" src={img} alt="" />
        </div>
        <div class="p-5">
          <h5 class=" text-primary-main justify-center flex text-sm tracking-tight uppercase">{name}</h5>
        </div>
      </div>
    </div>
  )
}
