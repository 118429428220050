import React from 'react'
import { useState } from 'react'
import { OutlineButton } from '../ui/buttons/OutlineButton';

export const ProjectCard = ({ title, desc, tech, imgs, rightSide=false, url, norepo, repo, shopifyTheme=false }) => {

    const [isHover, setIsHover] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    return (
        <div className='grid grid-cols-1 lg:grid-cols-3 lg:h-80 overflow-hidden space-y-4 lg:space-y-0'>
            <div 
                className={`group ${rightSide && 'lg:order-last'}`} 
                onMouseEnter={() => setIsHover(true)} 
                onMouseLeave={() => setIsHover(false)}
                onTouchStart={() => setIsTouched(true)}
                onTouchCancel={() => setIsTouched(false)}
            >
                <img className={`group-hover:hidden`} 
                    src={imgs[0]} 
                    alt="" 
                />
                <img 
                    className={`hidden group-hover:flex opacity-0${isHover||isTouched ? 'opacity-100 transition duration-200' : ''}`} 
                    src={imgs[1]} 
                    alt=""
                />
            </div>

            <div className='grid grid-cols-1 col-span-2 place-items-center lg:px-10'>
                <span className='lg:mb-0 mb-4 uppercase'>{title}</span>
                <span className='mb-4 lg:mb-0'>{desc}</span>
                <span className='mb-4 lg:mb-0'> {shopifyTheme && 'Password: 123'} </span>
                <span> 
                    <span className='text-primary-main mr-2 flex mb-2'> Technologies used</span> 
                    <div className='grid grid-cols-4 gap-2'>
                        {
                            tech.map(element => (
                                <span key={element} className='bg-secondary-dark text-sm font-extralight py-1 flex px-4 items-center justify-center text-gray-100 rounded-sm'>{element}</span>
                            ))
                        } 
                    </div>
                </span>

                <div className='mt-8 lg:mt-0 space-x-4'>
                         <a href={url} target="_blank" rel='noopener noreferrer'>
                            <OutlineButton>Live</OutlineButton>
                        </a>

                    {
                        norepo && 
                        <a href="/#contact">
                            <OutlineButton> Ask for repo! </OutlineButton>
                        </a>

                    }
                    {
                        !norepo &&
                        <a href={repo} target="_blank" rel='noopener noreferrer'>
                            <OutlineButton>Repo</OutlineButton>
                        </a>
                    }
                </div>
            </div>
        </div>
    )
}
