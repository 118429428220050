import React from 'react'
import { PrimaryButton } from './ui/buttons/PrimaryButton'

export const Contact = () => {

  const openMail = () => {
    window.location.href = `mailto:${process.env.REACT_APP_EMAIL}`;
  }

  return (
    <div className='min-h-screen text-white flex justify-center items-center p-6 bg-fixed bg-hero bg-no-repeat bg-cover' id='contact' /* style={{backgroundImage: "url('assets/home/bg.jpg')"}} */>

      <div className='space-y-6'>
        <div className='text-primary-main flex justify-center text-5xl'>
          GET IN TOUCH
        </div>

        <div className='text-lg pb-10 text-center'>
          Want to work togheter or have any question ?
        </div> 

        {/* <button className=' border-2 border-primary-main p-6 w-full transition ease-in-out duration-200 hover:bg-primary-main hover:text-black text-lg font-bold'>LET'S TALK</button> */}
        <div className='flex justify-center' onClick={openMail}>
          <PrimaryButton text="Let's talk"/>
        </div>
      </div>

    </div>
  )
}
