import React from 'react'
import { Model3D } from './3D/Model3D'
import { LogoCard } from './card/LogoCard'

export const TechStack = () => {

  const techs = [
    {img: 'assets/logos/HTML5-logo.png', name: 'Html 5'},
    {img: 'assets/logos/css_logo.svg', name: 'Css 3'},
    {img: 'assets/logos/js_logo.svg', name: 'Javascript'},
    {img: 'assets/logos/nextjs_logo.svg', name: 'Next Js'},
    {img: 'assets/logos/react_logo.svg', name: 'React'},
    {img: 'assets/logos/ts-logo-128.png', name: 'Typescript'},
    {img: 'assets/logos/tailwind_css_logo.svg', name: 'Tailwind Css'},
    {img: 'assets/logos/git_logo.svg', name: 'Git'},
    {img: 'assets/logos/firebase_logo.svg', name: 'Firebase'},
    {img: 'assets/logos/express_logo.svg', name: 'Express Js'},
    {img: 'assets/logos/illustrator_logo.svg', name: 'Adobe Illustrator'},
    {img: 'assets/logos/adobe_xd_logo.svg', name: 'Adobe XD'},
    {img: 'assets/logos/figma-logo.svg', name: 'Figma'},
    {img: 'assets/logos/liquid_logo.png', name: 'Liquid'},
  ];

  return (
    <div className='text-white space-y-6 flex flex-col  min-h-screen'>

      <div class="relative flex items-center px-6">
          <span class="flex-shrink  text-2xl lg:text-4xl text-left text-primary-main uppercase pr-2">Tech stack</span>
        <div class="flex-grow border-t border-primary-main opacity-40"></div>
      </div>
            
      <div className='grid grid-cols-2 lg:grid-cols-6 lg:gap-6'>
        {
          techs.map(tech => (
            <LogoCard img={tech.img} name={tech.name}/>
          ))
        }
      </div>

    </div>
  )
}
