import React from 'react'

export const PrimaryButton = ({ text }) => {
  return (
    // <div className='box uppercase p-6 w-full transition ease-in-out duration-200 
    // hover:bg-primary-main hover:text-black text-lg font-bold'>
    //           <span></span>
    //           <span></span>
    //           <span></span>
    //           <span></span>
    //     {text}
    // </div>
    // <button className='border-2 uppercase border-primary-main p-6 w-full transition ease-in-out duration-200 
    // hover:bg-primary-main hover:text-black text-lg font-bold'>
    //           <span></span>
    //           <span></span>
    //           <span></span>
    //           <span></span>
    //     {text}
    // </button>

    <div className='box uppercase'>
      <span></span>
      <h2>{text}</h2>
    </div>
  )
}
