import React from 'react'
import { PrimaryButton } from './ui/buttons/PrimaryButton'

export const Home = () => {

  const windowWidth = window.innerWidth;

  const img = windowWidth < 500 ? 'assets/home/O9FG5M0.jpg' : 'assets/home/O9FG5M0.jpg';
  
  return (
    <div className={`min-h-screen uppercase text-white flex flex-col justify-center items-center ${windowWidth>500 && 'bg-fixed'} bg-center bg-no-repeat bg-cover`} 
      style={{backgroundImage: `url(${img})`}}
    >
      <div className=' bg-black opacity-50 z-0 w-screen min-h-screen absolute'></div>
      {/* <div className='min-h-screen uppercase text-white flex flex-col justify-center items-center bg-fixed bg-hero bg-no-repeat bg-cover' style={{backgroundImage: "url('assets/home/bg.jpg')"}}></div> */}
      <div className='mb-2 z-50 text-center'>
        <h1 className='lg:text-7xl text-4xl'>
          Andrés Vera
        </h1>
      </div>
      <div className=' z-50'>
        <h2 className='lg:text-3xl text-xl text-primary-main'>
          Web Developer 
        </h2> 
      </div>
      <div className='mt-10'>
        <a href="/#projects">
          <PrimaryButton text='View projects'/>
        </a>
      </div>
    </div>
  )
}
